input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #1f1f1f4d;
  border-radius: 1.3px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: #1f1f1f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #1f1f1f4d;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #1f1f1f4d;
  border-radius: 1.3px;
}

input[type="range"]::-moz-range-thumb {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: #1f1f1f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #1f1f1f4d;
  border-radius: 1.3px;
}

input[type="range"]::-ms-fill-lower {
  background: #1f1f1f4d;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}

input[type="range"]::-ms-fill-upper {
  background: #1f1f1f4d;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}

input[type="range"]::-ms-thumb {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: #1f1f1f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #1f1f1f4d;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #1f1f1f4d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
